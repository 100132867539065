<template>
    <div>

    <v-list
            flat
            class="pt-0"
    >
        <draggable
                v-model="tasks"
                handle=".handle"
        >
            <task
                    v-for="task in tasks"
                    class="task"
                    :key="task.id"
                    :task="task"
            />
        </draggable>
    </v-list>
    <v-footer padless>
        <v-col
                class="text-center"
                cols="12"
        >
            <v-btn @click="myFunction()">
                <v-img
                        lazy-src="https://www.themoviedb.org/assets/2/v4/logos/v2/blue_long_1-8ba2ac31f354005783fab473602c34c3f4fd207150182061e425d366e4f34596.svg"
                        max-height="150"
                        max-width="250"
                        src="https://www.themoviedb.org/assets/2/v4/logos/v2/blue_long_1-8ba2ac31f354005783fab473602c34c3f4fd207150182061e425d366e4f34596.svg"
                ></v-img>

            </v-btn>

            <v-card-text @click="rmFunction()" class="text-decoration-underline">todd@rimesmedia.com</v-card-text>

        </v-col>
    </v-footer>
    </div>
</template>

<script>
  import draggable from 'vuedraggable'
  export default {
    name: "ListTasks",
    components: {
      'task': require('@/components/Todo/Task.vue').default,
      draggable
    },
    computed: {
      tasks: {
        get() {
          return this.$store.getters.tasksFiltered
        },
        set(value) {
          this.$store.dispatch('setTasks', value)
        }
      }
    },
    methods:{
      myFunction: function () {
        window.open("https://www.themoviedb.org/", "_blank");
      },
      rmFunction: function () {
        window.open("http://www.rimesmedia.com/#portfolio", "_blank");
      }
    }
  }
</script>

<style>

</style>
