<template>
    <div
            class="no-tasks"
    >
        <v-icon size="100" color="blue">mdi-check</v-icon>
        <div class="text-h5 blue--text">No Films</div>
    </div>
</template>

<script>
  export default {
    name: "NoTasks"
  }
</script>

<style lang="sass">
    .no-tasks
        position: absolute
        left: 50%
        top: 50%
        transform: translate(-50%, -50%)
        opacity: 0.5
</style>
