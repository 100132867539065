<template>
  <div class="home">
    <list-tasks
            v-if="$store.state.tasks.length" />
    <no-tasks
            v-else />
    <button-done-sorting
            v-if="$store.state.sorting"
    />
  </div>
</template>
<!-- v-if="$store.state.tasks.length" -->
<script>
  import NoTasks from "../components/Todo/NoTasks";
  export default {
  name: 'Todo',
  components: {
    NoTasks,
    'list-tasks': require('@/components/Todo/ListTasks.vue').default,
    'no-tasks': require('@/components/Todo/NoTasks.vue').default,
    'button-done-sorting': require('@/components/Todo/ButtonDoneSorting.vue').default,
  }
}
</script>
