<template>
    <div>
        <v-list-item
                :class="{ 'amber lighten-5': task.done }"
                :ripple="false"
                class="white"
                two-line
        >
            <template v-slot:default>
                <v-list-item-action>
                    <v-checkbox
                            @click="$store.dispatch('doneTask', task.id)"
                            :input-value="task.done"
                            color="primary"
                    ></v-checkbox>
                </v-list-item-action>

                <v-list-item-content>
                    <v-list-item-title>{{task.title}}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-action v-if="task.dueDate">
                    <v-list-item-action-text>
                        <v-icon small>mdi-calendar</v-icon>
                        {{ task.dueDate | niceDate }}
                    </v-list-item-action-text>
                </v-list-item-action>

                <v-list-item-action>
                    <task-menu :task="task"/>
                </v-list-item-action>

                <v-list-item-action
                        v-if="$store.state.sorting"
                >
                    <v-btn
                        color="primary"
                        class="handle"
                        icon
                >
                    <v-icon>mdi-drag-horizontal-variant</v-icon>
                </v-btn>
                </v-list-item-action>
            </template>
        </v-list-item>
        <v-divider></v-divider>
    </div>
</template>

<script>
  import { format } from 'date-fns'
  export default {
    name: "Task",
    filters: {
      "niceDate": function (value) {
        let dt = new Date(value)
        let dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000)
        return format(dtDateOnly, 'MMM dd')
      },
      "imdbLink": function (value) {
        let link = ""
        if(value && value != 0){
          link = `http://imdb.com/${value}`
        }
        return link
      },
      "tmdbLink": function (value) {
        let link = ""
        if(value && value != 0){
          link = `https://www.themoviedb.org/movie/${value}`
        }
        return link
      }
    },
    props: [ 'task' ],
    components: {
      'task-menu': require('@/components/Todo/TaskMenu.vue').default
    }
  }
</script>

<style lang="sass">
    .sortable-ghost
        opacity: 0
    .sortable-drag
        box-shadow: 0 0 10px rgba(0,0,0,0.3)
</style>
