import Vue from 'vue'
import Vuex from 'vuex'
import Localbase from 'localbase'

let ff = new Localbase('ff')
ff.config.debug = false
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    appTitle: process.env.VUE_APP_TITLE,
    apiKey: process.env.VUE_APP_TMDB_KEY,
    imageSizes: [],
    search: null,
    tasks: [
/*      {
        id: 1,
        title: "Wake upo",
        done: false,
        dueDate: '2021-06-01'
      },
      {
        id: 2,
        title: "Get bananas",
        done: false,
        dueDate: '2021-06-02'
      },
      {
        id: 3,
        title: "Eat bananas",
        done: false,
        dueDate: '2021-06-03'
      }*/
    ],
    snackbar: {
      show: false,
      text: ''
    },
    sorting: false
  },
  mutations: {
    setSearch(state, value) {
      state.search = value
    },
    addTask(state, newTask) {
      state.tasks.push(newTask)
    },
    doneTask(state, id) {
      let task = state.tasks.filter(task => task.id === id)[0]
      task.done = !task.done
    },
    deleteTask(state, id) {
      state.tasks = state.tasks.filter(task => task.id !== id)
    },
    updateTaskTitle(state, payload) {
      let task = state.tasks.filter(task => task.id === payload.id)[0]
      task.title = payload.title
    },
    updateTaskDueDate(state, payload) {
      let task = state.tasks.filter(task => task.id === payload.id)[0]
      task.dueDate = payload.dueDate
    },
    setTasks(state, tasks) {
      state.tasks = tasks
    },
    showSnackbar(state, text) {
      let timeout = 0
      if (state.snackbar.show == true) {
        state.snackbar.show = false
        timeout = 300
      }
      setTimeout(() => {
        state.snackbar.show = true
        state.snackbar.text = text
      }, timeout)
    },
    hideSnackbar(state) {
      state.snackbar.show = false
    },
    toggleSorting(state){
      state.sorting = !state.sorting
    }
  },
  actions: {
    addTask({ state, commit }, payload) {
      // console.log('add task')
      let exists = state.tasks.filter(task => task.title === payload.title)[0]
      if(!exists){
        let newTask = {
          id: Date.now(),
          title: payload.title,
          tmdbId: payload.tmdbId,
          image: '',
          imdbId: payload.imdbId,
          done: false,
          dueDate: null
        }
        ff.collection('tasks').add(newTask).then(()=>{
          commit('addTask', newTask)
          commit('showSnackbar', 'Movie added!')
        })
      }
    },
    doneTask({ state, commit }, id) {
      let task = state.tasks.filter(task => task.id === id)[0]
      ff.collection('tasks').doc({ id: id }).update({
        done: !task.done
      }).then(() => {
        commit('doneTask', id)
      })
    },
    updateTaskTitle({ state, commit }, payload) {
      let task = state.tasks.filter(task => task.id === payload.id)[0]
      ff.collection('tasks').doc({ id: task.id }).update({
        title: payload.title
      }).then(() => {
        commit('updateTaskTitle', payload)
        commit('showSnackbar', 'Movie updated.')
      })
    },
    deleteTask({ state, commit }, id) {
      let task = state.tasks.filter(task => task.id === id)[0]
      ff.collection('tasks').doc({ id: id }).delete().then(() => {
        commit('deleteTask', id)
        commit('showSnackbar', 'Movie deleted.')
      })
    },
    updateTaskDueDate({ state, commit }, payload) {
      let task = state.tasks.filter(task => task.id === payload.id)[0]
      ff.collection('tasks').doc({ id: task.id }).update({
        dueDate: payload.dueDate
      }).then(() => {
        commit('updateTaskDueDate', payload)
        commit('showSnackbar', 'Date updated.')
      })
    },
    setTasks({ commit }, tasks ) {
      ff.collection('tasks').set(tasks)
      commit('setTasks', tasks)
    },
    getTasks({ commit }) {
      let tasks = ff.collection('tasks').get().then(tasks => {
        commit('setTasks', tasks)
      })
    }
  },
  getters: {
    tasksFiltered(state) {
      if(!state.search) {
        return state.tasks
      }
      return state.tasks.filter(task => task.title.toLowerCase().includes(state.search.toLowerCase()))
    }
  },
  modules: {
  }
})

/* data is in state
SYNCHRONOUS change methods are in mutations
ASYNCHRONOUS actions are allowed
so --> (dispatch) actions --> (commit) mutation --> state
getters get data from state
modules allows us to break up  Vuex store into multiple modules.
e.g. calendar module and todo module
 */
