<template>
    <div>
        <div style="font-weight: bold; padding: 5px">id,title,date,seen,image,tmdbId,imdbId</div>
                <div
                        v-for="task in tasks"
                        :key="task.id"
                        :task="task"
                        style="padding: 0px 5px"
                >{{task.id}},{{task.title}},{{task.dueDate}},{{task.done}},{{task.image}},{{task.tmdbId}},{{task.imdbId}}</div>
    </div>
</template>

<script>
  export default {
    name: "MyData",
    computed: {
      tasks: {
        get() {
          return this.$store.getters.tasksFiltered
        },
      }
    }
  }
</script>

<style scoped>

</style>
