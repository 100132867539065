<template>
  <v-app id="inspire">
    <div
            class="navbar"
            :class="{ 'navbar--hidden': !showNavbar }"
    ></div>
    <v-navigation-drawer
            v-model="drawer"
            app
            :mobile-breakpoint="768"
    >

      <v-img
              class="pa-4"
              src="popcorn.jpg"
              height="150"
              gradient="to top right, rgba(19,84,122,.9), rgba(128,208,199,.9)"
      >
      </v-img>

      <v-list
              dense
              nav
      >
        <v-list-item
                v-for="item in items"
                :key="item.title"
                :to="item.to"
                link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

    </v-navigation-drawer>

    <v-app-bar
            app
            color="primary"
            dark
            src="popcorn.jpg"
            prominent
            :height="$route.path === '/' ? 220 : 150"
    >
      <template v-slot:img="{ props }">
        <v-img
                v-bind="props"
                gradient="to top right, rgba(19,84,122,.9), rgba(128,208,199,.9)"
        ></v-img>
      </template>

      <v-container
              class="header-container pa-0"
      >
        <v-row>
          <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
          <v-spacer></v-spacer>
          <search v-if="$route.path ==='/'" />
        </v-row>
        <v-row>
          <v-toolbar-title class="text-h4 ml-4"
          >{{ $store.state.appTitle }}</v-toolbar-title>
        </v-row>
        <v-row>
          <live-date-time />
        </v-row>
        <v-row v-if="$route.path ==='/'">
          <field-add-task />
        </v-row>
      </v-container>
    </v-app-bar>


    <v-main>
      <router-view></router-view>
      <snackbar />
    </v-main>
  </v-app>
</template>

<script>
  export default {
    data: () => ({
      showNavbar: true,
      lastScrollPosition: 0,
      drawer: false,
      items: [
        { title: 'Movie List', icon: 'mdi-format-list-checks', to: '/' },
        { title: 'About', icon: 'mdi-information-outline', to: '/about' },
        { title: 'My Data', icon: 'mdi-file-delimited-outline', to: '/data' },
      ],
    }),
    mounted() {
      this.$store.dispatch('getTasks')
      window.addEventListener('scroll', this.onScroll)
      // how to local orientation to portrait only? DONE in App.vue w/ CSS
      // how to get the current screen width?
      let intViewportWidth = window.innerWidth;
      this.$store.state.imageSizes = []
      fetch(`https://api.themoviedb.org/3/configuration?api_key=${this.$store.state.apiKey}`)
              .then(res => res.json())
              .then(res => {
                const { images } = res
                for (var key in images) {
                  if(key.indexOf("_sizes") > 1) {
                    let found = false;
                    images[key].forEach(wName => {
                      let pieces = wName.split("w");
                      let width = pieces[1];
                      if (found !== true && width && width > intViewportWidth) {
                        if (!this.$store.state.imageSizes[key]) {
                          this.$store.state.imageSizes.push({key: key, value: wName})
                          found = true
                        }
                      }
                    })
                  }
                }
/*                let temp = this.$store.state.imageSizes
                let result = ""
                result = JSON.stringify(temp)
                console.log("image sizes are: " + result)*/
              })
              .catch(err => {
                console.log(err)
              })
              .finally(() => (this.isLoading = false))

    },
    beforeDestroy () {
      window.removeEventListener('scroll', this.onScroll)
    },
    components: {
      'field-add-task': require('@/components/Todo/FieldAddTask.vue').default,
      'live-date-time': require('@/components/Tools/LiveDateTime.vue').default,
      'search': require('@/components/Tools/Search.vue').default,
      'snackbar': require('@/components/Shared/Snackbar.vue').default
    },
    methods: {
      onScroll () {
        const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
        if (currentScrollPosition < 0) {
          return
        }
        // Stop executing this function if the difference between
        // current scroll position and last scroll position is less than some offset
        if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
          return
        }
        this.showNavbar = currentScrollPosition < this.lastScrollPosition
        this.lastScrollPosition = currentScrollPosition
      }
    }
  }
</script>
<style>
  @media screen and (min-aspect-ratio: 13/9) {
    html {
      transform: rotate(-90deg);
      transform-origin: left top;
      width: 100vh;
      overflow-x: hidden;
      position: absolute;
      top: 100%;
      left: 0;
    }
    .bw-dashboard {
      height: inherit;
    }
  }
  @media screen and (min-width: 1292px) {
    html {
      transform: none;
      transform-origin: none;
      width: 100%;
      overflow-x: none;
      position: relative;
    }
    .bw-dashboard {
      height: 100vh;
    }
  }
  * {
    box-sizing: border-box;
  }
  body {
    padding: 0;
    margin: 0;
  }
  .app {
    width: 100vw;
    height: 500vh;
    background: hsl(200, 50%, 90%);
  }
  .navbar {
    height: 60px;
    width: 100vw;
    background: hsl(200, 50%, 50%);
    position: fixed;
    box-shadow: 0 2px 15px rgba(71, 120, 120, 0.5);
    transform: translate3d(0, 0, 0);
    transition: 0.1s all ease-out;
  }
  .navbar.navbar--hidden {
    box-shadow: none;
    transform: translate3d(0, -100%, 0);
  }
</style>
<style lang="sass">
  .v-app-bar-title__placeholder, .v-app-bar-title__content
    text-overflow-ellipsis: none
    text-overflow: unset
    width: 162px
  .header-container
    max-width: none
  .v-list-item--link:before
    background-color: darkblue
    transition: none
</style>
