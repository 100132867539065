<template>
    <div>
        <v-menu
                bottom
                left
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                        color="primary"
                        icon
                        v-bind="attrs"
                        v-on="on"
                >
                    <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
            </template>

            <v-list>
                <v-list-item
                        v-for="(item, index) in items"
                        :key="index"
                        @click="handleClick(index)"
                >
                    <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <dialog-edit v-if="dialogs.edit" :task="task" @close="dialogs.edit=false"/>
        <dialog-due-date v-if="dialogs.dueDate" :task="task" @close="dialogs.dueDate=false"/>
        <dialog-delete v-if="dialogs.delete" :task="task" @close="dialogs.delete=false"/>
    </div>

</template>

<script>
  export default {
    name: "TaskMenu",
    props : [ 'task' ],
    data: () => ({
      dialogs: {
        edit: false,
        dueDate: false,
        delete: false
      },
      items: [
        { title: 'IMDB',
          icon: 'mdi-ticket',
          click() {
            window.open(`https://www.imdb.com/title/${this.$props.task.imdbId}/`, "_blank");
          }
        },
        { title: 'TMDB',
          icon: 'mdi-movie-roll',
          click() {
            window.open(`https://www.themoviedb.org/movie/${this.$props.task.tmdbId}`, "_blank");
          }
        },
        { title: 'Edit',
          icon: 'mdi-pencil',
          click() {
            this.dialogs.edit = true
          }
        },
        { title: 'Date',
          icon: 'mdi-calendar',
          click() {
            this.dialogs.dueDate = true
          }
        },
        { title: 'Delete',
          icon: 'mdi-delete',
          click() {
            this.dialogs.delete = true
          }
        },
        { title: 'Sort',
          icon: 'mdi-drag-horizontal-variant',
          click() {
            if(!this.$store.state.search) {
              this.$store.commit('toggleSorting')
            } else {
              this.$store.commit('showSnackbar', 'Sort disabled when searching.')
            }
          }
        },
      ],
    }),
    methods: {
      handleClick(index) {
        this.items[index].click.call(this)
      }
    },
    components: {
      'dialog-due-date': require('@/components/Todo/Dialogs/DialogDueDate.vue').default,
      'dialog-edit': require('@/components/Todo/Dialogs/DialogEdit.vue').default,
      'dialog-delete': require('@/components/Todo/Dialogs/DialogDelete.vue').default
    }
  }
</script>

<style scoped>
    .mdi-ticket {
        color: #f5c518;
    }
    .mdi-movie-roll {
        color: #01b4e4;
    }
</style>
