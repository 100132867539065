<template>
    <v-card-text>
        <v-combobox
                v-model="model"
                :items="items"
                :loading="isLoading"
                :search-input.sync="search"
                color="white"
                hide-no-data
                hide-selected
                item-text="Description"
                item-value="id"
                placeholder="Type a Movie Title"
                append-icon="mdi-plus"
                return-object

                @keyup.enter="addTask"
                @click:append="addTask"
                class="field-add-task pa-3"
                clearable
        ></v-combobox>
    </v-card-text>
</template>

<script>
  export default {
    data() {
      return {
        newTaskTitle: '',
        tmdbId: 0,
        descriptionLimit: 60,
        entries: [],
        isLoading: false,
        model: null,
        search: null,
      }
    },
    computed: {
      newTaskTitleInvalid() {
        this.fields
        return !this.newTaskTitle
      },
      fields () {
        if (!this.model || this.model == '') return []
        //console.log("typeof is " + typeof this.model)
        if(typeof this.model == 'string') {
          // console.log("model is " + this.model)
          this.newTaskTitle = this.model
          this.tmdbId = 0
        } else {
          // need to populate small object
          let keys = Object.keys(this.model)
          this.newTaskTitle = this.model.title;
          this.tmdbId = this.model.id;
        }
      },
      items() {
        return this.entries.map(entry => {
          const Description = entry.title.length > this.descriptionLimit
              ? entry.title.slice(0, this.descriptionLimit) + '...'
              : entry.title

          return Object.assign({}, entry, { Description })
        })
      }
    },
    methods: {
      async addTask() {
        if (!this.newTaskTitleInvalid) {
          await this.fetchImdbIdFromTmdb(this.tmdbId).then( imdbId => {
                this.imdbId = imdbId
                console.log(`this.imdbId is ${this.imdbId}`)
                let payload = {
                  title: this.newTaskTitle,
                  tmdbId: this.tmdbId,
                  imdbId: this.imdbId
                }
                this.$store.dispatch('addTask', payload)
                this.newTaskTitle = ''
                this.tmdbId = 0
                this.entries = []
              }
          )
        }
      },
      async fetchImdbIdFromTmdb(tmdbId){
        let tmdbUrl = `https://api.themoviedb.org/3/movie/${tmdbId}?api_key=${this.$store.state.apiKey}&language=en-US`
        console.log(`tmdbUrl is "${tmdbUrl}"`)
        let imdbId = 0;
        await fetch(tmdbUrl)
            .then(res => res.json())
            .then(res => {
              imdbId = res.imdb_id
            })
            .catch(err => {
              console.log(err)
            })
        console.log(imdbId)
        return imdbId;
      }
    },
    watch: {
      search (val) {
        if(!val) return
        // Items have already been loaded
        // if (this.items.length > 0) return

        // Items have already been requested
        if (this.isLoading) return

        this.isLoading = true

        // Lazily load input items
        // console.log(this.$store)
        fetch(`https://api.themoviedb.org/3/search/movie?query=${val}&api_key=${this.$store.state.apiKey}&language=en-US&page=1&include_adult=false`)
            .then(res => res.json())
            .then(res => {
              const { results } = res
              this.entries = results
            })
            .catch(err => {
              console.log(err)
            })
            .finally(() => (this.isLoading = false))
      }
    }
  }
</script>

<style lang="sass">
    .field-add-task.v-input--is-focused
        margin-top: -5px
        .v-input__control
            margin-top: -5px
            background: rgba(31,94,129, 0.8)
            padding: 4px 3px 0px
            border-radius: 8px
        .v-icon.v-icon
            font-size: 34px
        button
            margin: 20px
</style>
